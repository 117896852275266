import { isLoggedIn } from '@/services/auth0-service';
export function requireAuth(to, from, next) {
    if (!isLoggedIn()) {
        next({
            path: '/',
            query: { redirect: to.fullPath },
        });
    }
    else
        next();
}
