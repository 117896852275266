var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SubscriptionStatus } from '@/services/subscription-status-service';
import AddressText from '../customer-info/AddressText.vue';
import DeleteCustomerControl from '../customer-info/DeleteCustomerControl.vue';
import StripeSubscriptionId from '../customer-info/StripeSubscriptionId.vue';
import StripeCustomerId from '../customer-info/StripeCustomerId.vue';
import TrialInfo from '../customer-info/TrialInfo.vue';
import CustomerDetailBox from './CustomerDetailBox.vue';
let CustomerDetailsDialog = class CustomerDetailsDialog extends Vue {
    getStatusCssClass() {
        switch (this.customer.status) {
            case SubscriptionStatus.ACTIVE:
                return 'customerStatusActive';
            case SubscriptionStatus.EXPIRED:
                return 'customerStatusExpired';
            case SubscriptionStatus.DISABLED:
                return 'customerStatusDisabled';
            case SubscriptionStatus.FREE:
                return 'customerStatusFree';
            case SubscriptionStatus.FREE_EXPIRED:
                return 'customerStatusExpired';
            case SubscriptionStatus.NO_SUBSCRIPTION:
                return 'customerStatusRegistered';
            default:
                return '';
        }
    }
    get billingAddress() {
        var _a;
        return ((_a = this.customer.stripeData) === null || _a === void 0 ? void 0 : _a.billingAddress) || null;
    }
    get payPerUse() {
        var _a, _b;
        if (((_a = this.customer.stripeData) === null || _a === void 0 ? void 0 : _a.payPerUse) === true)
            return 'Ja';
        if (((_b = this.customer.stripeData) === null || _b === void 0 ? void 0 : _b.payPerUse) === false)
            return 'Nein';
        return '-';
    }
    get stripeStatus() {
        var _a, _b;
        return (_b = (_a = this.customer.stripeData) === null || _a === void 0 ? void 0 : _a.stripeSubscriptionStatus) !== null && _b !== void 0 ? _b : 'Kein Abo';
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], CustomerDetailsDialog.prototype, "isVisible", void 0);
__decorate([
    Prop({ type: Object, required: true })
], CustomerDetailsDialog.prototype, "customer", void 0);
__decorate([
    Prop({ type: Boolean, required: true })
], CustomerDetailsDialog.prototype, "loading", void 0);
CustomerDetailsDialog = __decorate([
    Component({
        components: {
            CustomerDetailBox,
            AddressText,
            DeleteCustomerControl,
            TrialInfo,
            StripeCustomerId,
            StripeSubscriptionId,
        },
    })
], CustomerDetailsDialog);
export default CustomerDetailsDialog;
