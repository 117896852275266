var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CustomerServiceSingleton } from '@/services/customer-service';
let DeleteCustomerControl = class DeleteCustomerControl extends Vue {
    constructor() {
        super(...arguments);
        this.showDialog = false;
        this.confirmInfo = false;
        this.requestErrorMessage = '';
        this.email = '';
        this.pending = false;
    }
    async deleteCustomer() {
        if (this.email !== this.customer.auth0Email) {
            this.requestErrorMessage = "The email address you've entered is wrong. Please try again!";
            return;
        }
        this.requestErrorMessage = '';
        // disable dialog actions until we get a response from the server
        this.pending = true;
        try {
            await CustomerServiceSingleton.deleteCustomer(this.customer.id);
            this.showDialog = false;
            this.email = '';
            this.$emit('remove-entry', this.customer);
            this.confirmInfo = true;
        }
        catch (error) {
            if (error instanceof Error) {
                this.requestErrorMessage = error.message;
            }
        }
        this.pending = false;
    }
};
__decorate([
    Prop({ type: Object, required: true })
], DeleteCustomerControl.prototype, "customer", void 0);
DeleteCustomerControl = __decorate([
    Component
], DeleteCustomerControl);
export default DeleteCustomerControl;
