const prodConfig = {
    backendV2BaseUrl: 'https://backend-prod-v2.idana.app/api/v1',
    auth0: {
        redirectUri: 'https://admin.idana.app/callback',
        clientId: 'HSchQX9q32F7EDOZTkb30DjmWTwj1z3F',
        domain: 'idana.eu.auth0.com',
        audience: 'https://backend.tomes.gmbh',
    },
    stripeSubscriptionBaseUrl: 'https://dashboard.stripe.com/subscriptions',
    stripeCustomerBaseUrl: 'https://dashboard.stripe.com/customers',
};
export default prodConfig;
