var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import config from '@/config/config';
import { CustomerServiceSingleton } from '@/services/customer-service';
let StripeSubscriptionId = class StripeSubscriptionId extends Vue {
    constructor() {
        super(...arguments);
        this.valid = false;
        this.isDialogVisible = false;
        this.newSubscriptionId = '';
        this.requestErrorMessage = '';
        this.rules = [
            (subscriptionId) => /^$|^sub_\w{14}\w*$/.test(subscriptionId) || 'Subscription Id must be valid',
        ];
    }
    showDialog() {
        this.newSubscriptionId = this.customer.stripeSubscriptionId || '';
        this.isDialogVisible = true;
    }
    get stripeSubscriptionLink() {
        return `${config.stripeSubscriptionBaseUrl}/${this.customer.stripeSubscriptionId}`;
    }
    async saveSubscriptionIdToDb() {
        if (!this.form.validate())
            return;
        try {
            await CustomerServiceSingleton.patchStripeSubscriptionId(this.customer.id, this.newSubscriptionId);
            this.customer.stripeSubscriptionId = this.newSubscriptionId;
            this.isDialogVisible = false;
            this.$emit('stripe-subscription-id-changed', this.customer.id);
        }
        catch (error) {
            if (error instanceof Error) {
                this.requestErrorMessage = error.message;
            }
            else {
                this.requestErrorMessage = 'An error has occurred.';
            }
        }
    }
};
__decorate([
    Prop({ type: Object, required: true })
], StripeSubscriptionId.prototype, "customer", void 0);
__decorate([
    Ref('form')
], StripeSubscriptionId.prototype, "form", void 0);
StripeSubscriptionId = __decorate([
    Component
], StripeSubscriptionId);
export default StripeSubscriptionId;
