export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["NO_SUBSCRIPTION"] = "no-subscription";
    SubscriptionStatus["FREE"] = "free";
    SubscriptionStatus["FREE_EXPIRED"] = "free-expired";
    SubscriptionStatus["ACTIVE"] = "active";
    SubscriptionStatus["DISABLED"] = "disabled";
    SubscriptionStatus["EXPIRED"] = "expired";
})(SubscriptionStatus || (SubscriptionStatus = {}));
export class SubscriptionStatusService {
    static getStatus(freeAccountUntilTimestamp, stripeData) {
        if (!(stripeData === null || stripeData === void 0 ? void 0 : stripeData.stripeSubscriptionStatus)) {
            return SubscriptionStatusService.getFreeAccountStatus(freeAccountUntilTimestamp);
        }
        if (['active', 'trialing', 'past_due', 'incomplete'].includes(stripeData.stripeSubscriptionStatus)) {
            return SubscriptionStatus.ACTIVE;
        }
        if (freeAccountUntilTimestamp) {
            return SubscriptionStatusService.getFreeAccountStatus(freeAccountUntilTimestamp);
        }
        if (['incomplete_expired', 'unpaid'].includes(stripeData.stripeSubscriptionStatus)) {
            return SubscriptionStatus.DISABLED;
        }
        return SubscriptionStatus.EXPIRED;
    }
    static getFreeAccountStatus(freeAccountUntilTimestamp) {
        if (!freeAccountUntilTimestamp)
            return SubscriptionStatus.NO_SUBSCRIPTION;
        if (new Date(freeAccountUntilTimestamp) > new Date()) {
            return SubscriptionStatus.FREE;
        }
        return SubscriptionStatus.FREE_EXPIRED;
    }
}
