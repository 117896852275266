var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import config from '@/config/config';
import { CustomerServiceSingleton } from '@/services/customer-service';
let StripeCustomerId = class StripeCustomerId extends Vue {
    constructor() {
        super(...arguments);
        this.valid = false;
        this.isDialogVisible = false;
        this.newCustomerId = '';
        this.requestErrorMessage = '';
        this.rules = [
            (customerId) => /^$|^cus_\w{14}\w*$/.test(customerId) || 'Customer Id must be valid',
        ];
    }
    showDialog() {
        this.newCustomerId = this.customer.stripeCustomerId || '';
        this.isDialogVisible = true;
    }
    get stripeCustomerLink() {
        return `${config.stripeCustomerBaseUrl}/${this.customer.stripeCustomerId}`;
    }
    async saveCustomerIdToDb() {
        if (!this.form.validate())
            return;
        try {
            await CustomerServiceSingleton.patchStripeCustomerId(this.customer.id, this.newCustomerId);
            this.customer.stripeCustomerId = this.newCustomerId;
            this.isDialogVisible = false;
            this.$emit('stripe-customer-id-changed', this.customer.id);
        }
        catch (error) {
            if (error instanceof Error) {
                this.requestErrorMessage = error.message;
            }
            else {
                this.requestErrorMessage = 'An error has occurred.';
            }
        }
    }
};
__decorate([
    Prop({ type: Object, required: true })
], StripeCustomerId.prototype, "customer", void 0);
__decorate([
    Ref('form')
], StripeCustomerId.prototype, "form", void 0);
StripeCustomerId = __decorate([
    Component
], StripeCustomerId);
export default StripeCustomerId;
