import decode from 'jwt-decode';
import auth0 from 'auth0-js';
import config from '../config/config';
import router from '../router';
const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';
const storage = localStorage;
const auth = new auth0.WebAuth({
    clientID: config.auth0.clientId,
    domain: config.auth0.domain,
});
export function login() {
    auth.authorize({
        responseType: 'token id_token',
        redirectUri: config.auth0.redirectUri,
        audience: config.auth0.audience,
    });
}
export function logout() {
    clearIdToken();
    clearAccessToken();
    router.replace('/');
}
export function getIdToken() {
    return storage.getItem(ID_TOKEN_KEY);
}
export function getAccessToken() {
    return storage.getItem(ACCESS_TOKEN_KEY);
}
function clearIdToken() {
    storage.removeItem(ID_TOKEN_KEY);
}
function clearAccessToken() {
    storage.removeItem(ACCESS_TOKEN_KEY);
}
/** Helper function that will allow us to extract the access_token and id_token */
function getParameterByName(name) {
    const match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}
/** Get and store access_token in local storage */
export function setAccessToken() {
    const accessToken = getParameterByName('access_token');
    storage.setItem(ACCESS_TOKEN_KEY, accessToken || '');
}
/** Get and store id_token in local storage */
export function setIdToken() {
    const idToken = getParameterByName('id_token');
    storage.setItem(ID_TOKEN_KEY, idToken || '');
}
export function isLoggedIn() {
    const idToken = getIdToken();
    if (!idToken)
        return false;
    return !isTokenExpired(idToken);
}
function getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp)
        return null;
    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date;
}
function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return !expirationDate || expirationDate < new Date();
}
