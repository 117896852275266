import axios from 'axios';
import config from '../config/config';
import { formatDates, formatDate } from './format-dates';
import { getAccessToken } from './auth0-service';
import { AdminDashboardCustomerPatchKeys } from './request.interfaces';
import { SubscriptionStatusService } from './subscription-status-service';
export class CustomerService {
    constructor() {
        this.customers = [];
    }
    get displayCustomers() {
        return this.customers.map((customer) => {
            var _a;
            const registrationDate = formatDate(customer.registrationTimestamp);
            return {
                id: customer.id,
                institutionName: (_a = customer.institutionName) !== null && _a !== void 0 ? _a : 'Kein Name',
                auth0Email: customer.auth0Email,
                registrationDate,
            };
        });
    }
    async loadCustomers() {
        const { data: responseBody } = await axios.get(CustomerService.ADMIN_DASHBOARD_API_CUSTOMERS_URL, CustomerService.getRequestConfig());
        this.customers = responseBody.data;
    }
    async loadCustomer(customerId) {
        const customersApiUrl = CustomerService.ADMIN_DASHBOARD_API_CUSTOMERS_URL + customerId;
        const { data: responseData } = await axios.get(customersApiUrl, CustomerService.getRequestConfig());
        const customer = responseData.data;
        const status = SubscriptionStatusService.getStatus(customer.freeAccountUntilTimestamp, customer.stripeData);
        const formattedDates = formatDates(customer);
        return {
            ...customer,
            ...formattedDates,
            status,
        };
    }
    /**
     * Remove customer from the currently loaded list. Does not affect database.
     * @param customerId ID of customer to hide/remove from the list
     */
    async hideCustomer(customerId) {
        const index = this.customers.findIndex((customer) => customer.id === customerId);
        if (index < 0)
            return;
        this.customers.splice(index, 1);
    }
    /**
     * Delete customer from database
     * @param customerId ID of customer to delete
     */
    async deleteCustomer(customerId) {
        const deleteUrl = CustomerService.ADMIN_DASHBOARD_API_CUSTOMERS_URL + customerId;
        try {
            const response = await axios.delete(deleteUrl, CustomerService.getRequestConfig());
            if (response.status !== 204)
                throw new Error(response.data.message);
        }
        catch (error) {
            throw CustomerService.parseRequestError(error);
        }
    }
    async patchStripeCustomerId(customerId, newStripeCustomerId) {
        await this.patchCustomer(customerId, {
            [AdminDashboardCustomerPatchKeys.STRIPE_CUSTOMER_ID]: newStripeCustomerId,
        });
    }
    async patchStripeSubscriptionId(customerId, newStripeCustomerId) {
        await this.patchCustomer(customerId, {
            [AdminDashboardCustomerPatchKeys.STRIPE_SUBSCRIPTION_ID]: newStripeCustomerId,
        });
    }
    async patchFreeAccountUntilTimestamp(customerId, newTimestamp) {
        await this.patchCustomer(customerId, {
            [AdminDashboardCustomerPatchKeys.FREE_ACCOUNT_UNTIL_TIMESTAMP]: newTimestamp,
        });
    }
    async patchCustomer(customerId, patchBody) {
        const patchUrl = CustomerService.ADMIN_DASHBOARD_API_CUSTOMERS_URL + customerId;
        const response = await axios.patch(patchUrl, patchBody, CustomerService.getRequestConfig());
        if (response.status !== 204)
            throw new Error(response.data.message);
    }
    static getRequestConfig() {
        return { headers: { Authorization: `Bearer ${getAccessToken()}` } };
    }
    /**
     * Tries to parse a caught error and returns one with a readable message
     * This is required as Axios might throw for different reasons (non-2xx, network, CORS etc.)
     * @param error Error to inspect
     * @returns Error with wrapped error message, or a generic message if one could not be found
     */
    static parseRequestError(error) {
        if (error instanceof Error) {
            const possibleAxiosError = error;
            if (possibleAxiosError.response) {
                return new Error(possibleAxiosError.response.data.message);
            }
            else {
                return new Error(error.message);
            }
        }
        return new Error('An error has occurred.');
    }
}
CustomerService.ADMIN_DASHBOARD_API_CUSTOMERS_URL = config.backendV2BaseUrl + '/admin-dashboard/customers/';
export const CustomerServiceSingleton = new CustomerService();
