import { format, parseISO } from 'date-fns';
export function formatDates(customer) {
    var _a, _b, _c, _d;
    let registrationDate = '-';
    if (customer.registrationTimestamp) {
        registrationDate = formatDate(customer.registrationTimestamp);
    }
    let trialPeriod = 'Keine';
    if (customer.freeAccountUntilTimestamp) {
        const formattedTrialEndDate = formatDate(customer.freeAccountUntilTimestamp);
        if (new Date(customer.freeAccountUntilTimestamp) < new Date()) {
            trialPeriod = 'Abgelaufen seit ' + formattedTrialEndDate;
        }
        else {
            trialPeriod = 'Aktiv bis ' + formattedTrialEndDate;
        }
    }
    let endSubscriptionDate = '-';
    if ((_a = customer.stripeData) === null || _a === void 0 ? void 0 : _a.subscriptionEndTimestamp) {
        endSubscriptionDate = formatDate((_b = customer.stripeData) === null || _b === void 0 ? void 0 : _b.subscriptionEndTimestamp);
    }
    let startSubscriptionDate = '-';
    if ((_c = customer.stripeData) === null || _c === void 0 ? void 0 : _c.subscriptionStartTimestamp) {
        startSubscriptionDate = formatDate((_d = customer.stripeData) === null || _d === void 0 ? void 0 : _d.subscriptionStartTimestamp);
    }
    return {
        trialPeriod,
        registrationDate,
        startSubscriptionDate,
        endSubscriptionDate,
    };
}
export function formatDate(dateString) {
    return format(parseISO(dateString), 'yyyy-MM-dd');
}
