import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
Vue.config.productionTip = false;
import '@fontsource/roboto/latin.css';
import '@mdi/font/css/materialdesignicons.css';
new Vue({
    router,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
