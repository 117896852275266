var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { CustomerServiceSingleton } from '@/services/customer-service';
import PageHeader from '@/components/PageHeader.vue';
import CustomerDetailsDialog from '@/components/customer-details-dialog/CustomerDetailsDialog.vue';
let CustomerTable = class CustomerTable extends Vue {
    constructor() {
        super(...arguments);
        this.selectedCustomer = null;
        this.loading = false;
        this.loadingCustomer = false;
        this.searchText = '';
        this.headers = [
            { text: 'CID', value: 'id', width: '100' },
            { text: 'Praxis / Institution', value: 'institutionName', width: '500' },
            { text: 'Email', value: 'auth0Email' },
            { text: 'Registration Date', value: 'registrationDate' },
        ];
    }
    get displayCustomers() {
        if (this.loading)
            return [];
        return CustomerServiceSingleton.displayCustomers;
    }
    get isCustomerDialogVisible() {
        return this.selectedCustomer !== null || this.loadingCustomer;
    }
    async mounted() {
        this.loading = true;
        await CustomerServiceSingleton.loadCustomers();
        this.loading = false;
    }
    async onRowClick(customer) {
        this.loadingCustomer = true;
        this.setSelectedCustomer(null);
        const customerData = await CustomerServiceSingleton.loadCustomer(customer.id);
        this.setSelectedCustomer(customerData);
        this.loadingCustomer = false;
    }
    async onCustomerUpdate(customerId) {
        this.loadingCustomer = true;
        this.setSelectedCustomer(null);
        const customerData = await CustomerServiceSingleton.loadCustomer(customerId);
        this.setSelectedCustomer(customerData);
        this.loadingCustomer = false;
    }
    async onCustomerRemove(customerId) {
        this.loading = true;
        this.setSelectedCustomer(null);
        await CustomerServiceSingleton.hideCustomer(customerId);
        this.loading = false;
    }
    setSelectedCustomer(customer) {
        this.selectedCustomer = customer;
    }
};
CustomerTable = __decorate([
    Component({
        components: {
            PageHeader,
            CustomerDetailsDialog,
        },
    })
], CustomerTable);
export default CustomerTable;
