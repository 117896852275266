var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { CustomerServiceSingleton } from '@/services/customer-service';
import { formatDate } from '@/services/format-dates';
let TrialInfo = class TrialInfo extends Vue {
    constructor() {
        super(...arguments);
        this.valid = false;
        this.isDialogVisible = false;
        this.newTrialEndDate = '';
        this.requestErrorMessage = '';
        this.dateRules = [
            (date) => !!date || 'New trial date is required',
            (date) => /^(20[1-9][0-9])-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/.test(date) || 'Date must be valid - YYYY-MM-DD',
        ];
    }
    showDialog() {
        this.newTrialEndDate = this.customer.freeAccountUntilTimestamp
            ? formatDate(this.customer.freeAccountUntilTimestamp)
            : '';
        this.isDialogVisible = true;
    }
    saveTrialEndDate() {
        if (!this.form.validate())
            return;
        this.saveTrialEndDateToDb(this.newTrialEndDate);
    }
    async saveTrialEndDateToDb(newTrialEndDate) {
        try {
            await CustomerServiceSingleton.patchFreeAccountUntilTimestamp(this.customer.id, newTrialEndDate);
            this.customer.freeAccountUntilTimestamp = newTrialEndDate;
            this.isDialogVisible = false;
            this.$emit('trial-end-date-changed', this.customer.id);
        }
        catch (error) {
            if (error instanceof Error) {
                this.requestErrorMessage = error.message;
            }
            else {
                this.requestErrorMessage = 'An error has occurred.';
            }
        }
    }
};
__decorate([
    Prop({ type: Object, required: true })
], TrialInfo.prototype, "customer", void 0);
__decorate([
    Ref('form')
], TrialInfo.prototype, "form", void 0);
TrialInfo = __decorate([
    Component
], TrialInfo);
export default TrialInfo;
