import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import LoginCallback from '../views/LoginCallback.vue';
import CustomerTable from '../views/CustomerTable.vue';
import { requireAuth } from './guards';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/callback',
        component: LoginCallback,
    },
    {
        path: '/dashboard',
        beforeEnter: requireAuth,
        component: CustomerTable,
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
export default router;
